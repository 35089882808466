.full-page {
  height: 100vh;
  padding-top: 20vh;
  .box {
    background-color: #fff;
    max-width: 400px;
    margin: auto;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 15px;
  }
  h2 {
    margin: 0;
    padding: 10px 0 20px 0;
    font-size: 20px;
  }
}
