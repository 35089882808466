html, body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #454555;
}

.viz-tooltip {
  position: fixed;
  background-color: #232323;
  box-shadow: 0 0 4px 4px #0001;
  color: #eee;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 11px;
  font-weight: 500;
  z-index: 100;
  transform: translateX(-50%);
  white-space: pre-wrap;
}